<template>
  <div class="d-flex flex-column">

    <v-container fluid :class="{ withBottomNav: bottomNav}" class="scrolled-max-h grow">
      <router-view class="view "></router-view>
    </v-container>

    <v-bottom-navigation
      v-model="bottomNav"
      dark
      mandatory
    >
      <v-btn v-for="route in routes" :to="route" :key="route.name" :value="route.name">
        <span>{{ route.name }}</span>
      </v-btn>

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'SimpleView',
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      bottomNav: this.routes.length,
    };
  },
};
</script>

<style lang="scss" scoped>
.withBottomNav {
  --max-h: calc(100% - 56px);
}
</style>
